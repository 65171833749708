import { PlannerContext } from "@/contexts/PlannerContext";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useMemo, useContext, useState } from "react";
import {
  SHOPPING_LIST_GUID,
  addRecipeToShoppingList,
} from "@/components/ShoppingList/ShoppingListContainer";
import { useStores } from "@/hooks/useStores";
import Spinner from "@components/Spinner";
import { debounce, omit } from "lodash";
import usePaywall from "@/hooks/usePaywall";
import useSubscriptionPermission from "@/hooks/useSubscriptionPermission";
import TooltipPopover from "@/components/TooltipPopover";
import { getEventDataFromRecipe } from "@/src/recipes/lib/getEventDataFromRecipe";
import { useRouter } from "next/router";
import useGTM from "@/hooks/useGTM";

type Recipe = {
  id: number;
  [key: string]: unknown;
};

const SaveRecipeToShoppingList = ({ recipe }: { recipe: Recipe }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { planners, setPlanners, updatePlanner, setIsShoppingListOpen } =
    useContext(PlannerContext);
  const { authStore } = useStores();
  const currentUser = authStore.currentUser;
  const { setPaywall } = usePaywall();
  const { subscriptionPermission } = useSubscriptionPermission(recipe);
  const { canUsePlanner } = subscriptionPermission;
  const gtm = useGTM();

  const shoppingList = useMemo(() => {
    return (
      currentUser &&
      planners?.find((planner) => planner.title === SHOPPING_LIST_GUID)
    );
  }, [planners, currentUser]);

  const recipeInShoppingList = useMemo(() => {
    if (!shoppingList || !shoppingList.days) return false;

    return shoppingList.days[0].dayRecipes.some(
      (dayRecipe) => dayRecipe.recipeId.toString() === recipe.id.toString()
    );
  }, [shoppingList]);

  const handleAddToShoppingList = debounce(
    async () => {
      if (!canUsePlanner) {
        setPaywall({
          isOpen: true,
          isClosable: true,
          variant: `addToShoppingList`,
        });

        return;
      }

      if (isLoading) return;

      try {
        gtm.pushEvent("add_to_shopping_list", {
          recipeAddedFrom: "thumbnail",
          ...getEventDataFromRecipe(recipe),
        });

        setIsLoading(true);
        const response = await addRecipeToShoppingList({
          recipe,
          shoppingList,
          currentUser,
        });

        if (shoppingList) {
          updatePlanner(shoppingList.uid, response, true);
        } else {
          setPlanners([...planners, response]);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  return (
    <div className="SaveRecipe">
      <TooltipPopover
        disabled={isLoading}
        onClick={
          recipeInShoppingList
            ? () => setIsShoppingListOpen(true)
            : handleAddToShoppingList
        }
        side="right"
        avoidCollisions
        hideWhenDetached={false}
      >
        <TooltipPopover.TooltipTrigger
          className={clsx(
            "w-component h-component flex shrink-0 items-center justify-center space-x-1.5 rounded-full bg-zinc-950 text-zinc-50 transition ease-out  hover:bg-white hover:text-zinc-950"
          )}
          label="Add to Shopping List"
        >
          {isLoading ? (
            <Spinner />
          ) : recipeInShoppingList ? (
            <CheckIcon className="size-5 stroke-[1.5]" />
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="size-5 stroke-[1.5]"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 5.5L3 5.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M12.5 10L3 10"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M9.5 14.5L3 14.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M15 12.5L15 16.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 14.5L17 14.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </TooltipPopover.TooltipTrigger>
      </TooltipPopover>
    </div>
  );
};

export default observer(SaveRecipeToShoppingList);
