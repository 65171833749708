export const getEventDataFromRecipe = (recipe: any) => {
  // Create `ingredients` array of all ingredients names
  const ingredients = recipe.recipeIngredients
    ?.filter(
      (ingredient) =>
        ingredient.typeHandle === "ingredient" && ingredient.ingredient[0]
    )
    .map((ingredient) => {
      return ingredient.ingredient[0].title;
    });

  return {
    recipeTitle: recipe.title,
    cuisines: recipe.cuisines?.map((entry) => entry.title).join(", "),
    categories: recipe.recipeCategories?.map((entry) => entry.title).join(", "),
    type: recipe.types?.map((entry) => entry.title).join(", "),
    occasions: recipe.occasions?.map((entry) => entry.title).join(", "),
    dietary_requirements: recipe.dietaryRequirements
      ?.map((entry) => entry.title)
      .join(", "),
    chefs: recipe.chefs?.map((entry) => entry.title).join(", "),
    chefsIds: recipe.chefs?.map((entry) => entry.id).join(", "),
    meals: recipe.meals?.map((entry) => entry.title).join(", "),
    cooking_time_mins: recipe.cookTime,
    prep_time_mins: recipe.prepTime,
    total_time_mins: recipe.time,
    complexity: recipe.complexityString,
    serving_size: recipe.servingSize,
    isPremium: recipe.accessLevel === "2",
    accessLevel: recipe.accessLevel,
    ingredients,
    creatorProgrammeContent: recipe.creatorProgrammeContent,
    monetized: recipe.isMonetized,
  };
};
