import ChefToken from "@/components/ChefToken";
import SaveRecipeToShoppingList from "@/components/SaveRecipeToShoppingList";
import { pushEvent } from "@/lib/gtm";
import TimeIndicator from "@/src/components/CookingTime";
import Thumbnail from "@/src/components/thumbnail";
import clsx from "clsx";
import dayjs from "dayjs";
import { useStores } from "hooks/useStores";
import { observer } from "mobx-react";
import { useFeatureFlagPayload } from "posthog-js/react";
import { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import useGTM from "@/hooks/useGTM";

const RecipeThumbnail = ({
  entry,
  onClick = () => {},
  priority = false,
  tokenLabel = "Free",
  disableLink = false,
  avoidCollisions,
  saveRecipePopoverSide,
  noHover = false,
  rail = { id: null, title: null },
}) => {
  const gtm = useGTM();

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const chef = entry.chefs?.[0];
  const image = entry.image?.[0];
  const href = `/${entry?.uri || ""}`;

  const time = parseInt(entry.time || 0);

  const { authStore } = useStores();
  const { currentUser } = authStore;

  const shouldShowFreeLabel = (entry, currentUser) => {
    return (
      ["0", "1", "3"].includes(entry.accessLevel) && currentUser?.plan !== 2
    );
  };

  const handleClick = () => {
    gtm.pushEvent("recipe_thumbnail_clicked", {
      recipeID: entry.id,
      recipeTitle: entry.title,
      recipeTags: tagLabels,
      railTitle: rail.title,
      railID: rail.id,
    });

    onClick();
  };

  const isMoreThanOneDayOld = dayjs().diff(dayjs(entry.postDate), "day") > 1;

  const handleSaveRecipeClick = () => {
    pushEvent("saveRecipeFromThumbnailIntent", {
      recipeId: entry.id,
      recipeTitle: entry.title,
    });
  };

  const tagPayload = useFeatureFlagPayload(
    "PMF-WEB-01_Recipe_Thumbnail_Tagging"
  ) as Record<string, string> | undefined;

  const tagLabels = useMemo(() => {
    const labels = [];
    for (const tagKey in tagPayload) {
      const tagLabel = tagPayload[tagKey];
      if (entry._tags?.includes(tagKey)) {
        labels.push(tagLabel);
      }
    }

    return labels.length > 0 ? labels : null;
  }, [entry, tagPayload]);

  return (
    <Thumbnail.Root theme="dark" onClick={handleClick} ref={ref}>
      <Thumbnail.Image
        src={image?.url}
        alt={image?.title}
        priority={priority}
        href={!disableLink && !!href ? href : undefined}
        noHover={noHover}
      >
        <div
          className={clsx(
            "absolute left-4 top-4 flex gap-1 font-body text-zinc-950"
          )}
        >
          {shouldShowFreeLabel(entry, currentUser) && (
            <div
              className={clsx(
                "h-component-sm flex items-center justify-center rounded-full bg-acid px-3 font-body text-sm"
              )}
            >
              {tokenLabel}
            </div>
          )}
          {tagLabels?.map((tagLabel) => (
            <div
              key={tagLabel}
              className={clsx(
                "h-component-sm flex items-center justify-center rounded-full bg-rust-200 px-3 font-body text-sm"
              )}
            >
              {tagLabel}
            </div>
          ))}
        </div>

        <div className={clsx("absolute right-4 top-4")}>
          <SaveRecipeToShoppingList recipe={entry} />
        </div>
      </Thumbnail.Image>

      <Thumbnail.Caption>
        <Thumbnail.Header href={!disableLink && !!href ? href : undefined}>
          {entry.title}
        </Thumbnail.Header>

        <Thumbnail.Meta>
          {time > 0 && (
            <div className={clsx("relative -left-px")}>
              <TimeIndicator time={time} />
            </div>
          )}
          {/* TODO: removed ratings for now
          {isMoreThanOneDayOld && (
            <_Rating elementId={entry.id} inView={inView} />
          )}
					*/}
        </Thumbnail.Meta>
      </Thumbnail.Caption>

      <Thumbnail.Footer>
        <ChefToken chef={chef} isMob={!chef} size="xs" theme="dark" />
        <_DietaryRequirement dietaryRequirements={entry.dietaryRequirements} />
      </Thumbnail.Footer>
    </Thumbnail.Root>
  );
};

const _DietaryRequirement = ({ dietaryRequirements }) => {
  if (!dietaryRequirements?.[0]) return null;

  return (
    <div className={clsx("hidden text-xs @3xs:block")}>
      {dietaryRequirements?.[0]?.title}
    </div>
  );
};

export default observer(RecipeThumbnail);
